<template>
  <div class="tool-item table-translate-tool" v-tooltip="{ text: 'Translate', position: 'left' }">
    <svg-icon @click="handleTranslate" icon="translate"/>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import translates from "../../../../api/modules/translates";
import {mapGetters} from "vuex";
import Tooltip from "../../../UI/Tooltip/index.vue";

export default defineComponent({
  name: "TranslateOneTool",
  components: {Tooltip},
  props: {
    toTranslateString: {
      type: String,
      required: true,
    },
    setResultTo: {
      type: Object,
    },
    setResultToProp: {
      type: String,
      default: 'translate',
    },
  },
  data() {
    return {}
  },
  methods: {
    async handleTranslate() {
      //TODO плохая практика делать мутацию пропса, но пока так. Думаю можно в будущем подписать место перевода на событие перевода, так скорее всего правильнее.
      if (this.setResultTo[this.setResultToProp]) {
        this.setResultTo[this.setResultToProp] = null;
      } else {
        this.setResultTo[this.setResultToProp] = await translates.translateKeyword(this, this.currentApp.store.key, this.toTranslateString);
      }
    }
  },
  computed: {
    ...mapGetters([
        'currentApp'
    ]),
  },
})
</script>

<style scoped lang="scss">
.table-translate-tool {
  svg {
    font-size: 24px;
    color: #a3b0c5;

    &:hover {
      color: var(--primary-default);
    }
  }
}
</style>