<script>
import SimpleInfoTable from "../../../../components/SimpleInfoTable/SimpleInfoTable.vue";
import pagination from "../../../../components/Pagination/index.vue";
import SvgIcon from "../../../../components/UI/SvgIcon/index.vue";

export default {
  name: "MetadataKeywordsTable",
  components: {SvgIcon, SimpleInfoTable},
  props: {
    store: {
      type: String,
      default: () => 'APP_STORE'
    },

    tableContainerStyles: {
      type: Object,
      default: () => ({})
    },

    title: {
      type: [String, Array],
      default: () => ''
    },
    subTitle: {
      type: [String, Array],
      default: () => ''
    },
    description: {
      type: [String, Array],
      default: () => ''
    },

    outerTexts: {
      type: Array,
      default: () => null
    },
    initTexts: {
      type: Array,
      default: () => [
        'title',
        'subtitle',
      ]
    },
    allowedCounts: {
      type: Array,
      default: () => [1, 2, 3]
    },
    initCount: {
      type: Number,
      default: 1
    },
    maxCountPlus: {
      type: Boolean,
      default: false
    },
  },
  created() {
    this.count = this.initCount;
    this.innerTexts = this.initTexts;
  },
  data() {
    return {
      count: 1,
      innerTexts: [],

      tableMounted: false,
    }
  },
  watch: {
    keywords() {
      this.$nextTick(() => {
        this.$refs.table.pageChanged(1);
      })
    }
  },
  computed: {
    pagination() {
      return pagination
    },
    texts() {
      return this.outerTexts ?? this.innerTexts;
    },
    textsProps() {
      let props = [];
      if (this.texts.includes('title'))
        props.push('title');
      if (this.texts.includes('subtitle'))
        props.push('subTitle');
      if (this.texts.includes('description'))
        props.push('description');

      return props;
    },
    maxCount() {
      return Math.max(...this.allowedCounts);
    },
    columns() {
      let columns = {
        keywords: {header: 'Keywords', style: {width: '100%'}, orientation: 'left', headerStyle: {paddingRight: '6px'}},
      };

      if (this.texts.includes('title'))
        columns.title = {header: 'Title', style: {padding: '8px 6px', minWidth: '64px'}, headerStyle: {padding: '11px 6px'}, orientation: 'right', sortBy: 'title'};
      if (this.texts.includes('subtitle'))
        columns.subtitle = {header: this.store === 'APP_STORE' ? 'Subtitle' : 'Sh.Desc', style: {padding: '8px 6px', minWidth: '90px'}, headerStyle: {padding: '11px 6px'}, orientation: 'right', sortBy: 'subtitle'};
      if (this.texts.includes('description'))
        columns.description = {header: 'Desc', style: {padding: '8px 6px', minWidth: '67px'}, headerStyle: {padding: '11px 6px'}, orientation: 'right', sortBy: 'description'};

      columns.total = {header: 'Total', style: {padding: '8px 6px', minWidth: '69px'}, headerStyle: {padding: '11px 6px'}, orientation: 'right', sortBy: 'total'};
      columns.density = {header: 'Density', style: {padding: '8px 20px 8px 6px', minWidth: '103px'}, headerStyle: {padding: '11px 20px 11px 6px'}, orientation: 'right', sortBy: (item) => Number(item.density)};

      return columns;
    },
    keywords() {
      const regex = /[^\p{L}\p{N}]+/u;
      let result = [];
      let counters = {};
      const texts = this.textsProps;

      for (let textI = 0; textI < texts.length; textI++) {
        if (!this[texts[textI]]) continue;

        let process = (processText) => {
          let terms = processText.split(regex).filter((term) => term.length).map((term) => term.toLowerCase());
          if (this.count === 1) {
            terms = terms.filter((term) => term.length > 2);
          }

          let pushTermsFn = (count) => {
            termsLoop: for (let i = 0; i < terms.length; i++) {
              let term = terms[i];

              for (let j = i + 1; j < i + count; j++) {
                if (!terms[j]) break termsLoop;

                term += ' ' + terms[j];
              }

              result.push(term);

              if (!counters[term])
                counters[term] = {};
              if (!counters[term][texts[textI]])
                counters[term][texts[textI]] = 0;

              counters[term][texts[textI]]++;
            }
          }

          pushTermsFn(this.count);
          if (this.maxCountPlus && this.count === this.maxCount) {
            pushTermsFn(this.count + 1);
            pushTermsFn(this.count + 2);
          }
        }

        let text = this[texts[textI]];

        if (text instanceof Array) {
          for (let i = 0; i < text.length; i++) {
            if (!text[i]) continue;

            process(text[i]);
          }
        } else {
          process(text);
        }
      }

      return [...new Set(result)].map((term) => {
        let total = (counters[term]?.title ?? 0) + (counters[term]?.subTitle ?? 0) + (counters[term]?.description ?? 0);
        return {
          term,
          title: counters[term]?.title ?? 0,
          subtitle: counters[term]?.subTitle ?? 0,
          description: counters[term]?.description ?? 0,
          total: total,
          density: Number(total / result.length * 100).toFixed(2)
        }
      });
    }
  },
  methods: {
    pageChanged(page) {
      if (!this.isAllowedPageChange(page)) return;

      this.$refs.table.pageChanged(page);
    },
    isAllowedPageChange(page) {
      let total = this.$refs.table.preparedPagination.total;
      let perPage = this.$refs.table.preparedPagination.perPage;

      const maxPage = Math.ceil(total / perPage);
      const minPage = 1;

      return page >= minPage && page <= maxPage;
    },
  },
}
</script>

<template>
  <div>
    <SimpleInfoTable
        ref="table"

        :table-container-styles="tableContainerStyles"

        :show-empty-slot="false"

        :columns="columns"
        :items="keywords"

        :per-page="10"
        :show-pagination="false"

        @mounted="tableMounted = true"

        default-sort-index="density"
        default-sort-direction="desc"
    >
      <template v-slot:containerStart v-if="!outerTexts">
        <div class="display-flex ml-15 mt-16 no-select">
          <div class="display-flex">
            <label class="cursor-pointer display-flex"><input v-model="innerTexts" type="checkbox" class="common-checkbox" value="title" :checked="true"> Title</label>
          </div>
          <div class="display-flex ml-8">
            <label class="cursor-pointer display-flex"><input v-model="innerTexts" type="checkbox" class="common-checkbox" value="subtitle" :checked="true"> <template v-if="store === 'APP_STORE'">Subtitle</template><template v-else>Sh.Desc</template></label>
          </div>
          <div class="display-flex ml-8">
            <label class="cursor-pointer display-flex"><input v-model="innerTexts" type="checkbox" class="common-checkbox" value="description" :checked="false"> Desc</label>
          </div>
        </div>
      </template>
      <template v-slot:headers="slotProps">
        <div v-if="slotProps.header === 'Keywords'" class="display-flex">
          {{ slotProps.header }}
          <div class="display-flex ml-12">
            <div v-for="(allowedCount, index) in allowedCounts" @click="count=allowedCount"
                 :class="{active:allowedCount === count, 'ml-8': index > 0}" class="countSelector">{{ allowedCount }}<span
                v-if="maxCountPlus && allowedCount === maxCount">+</span></div>
          </div>
        </div>
        <div v-else>
          {{ slotProps.header }}
        </div>
      </template>
      <template v-slot:items-keywords="slotProps">
        <div class="display-flex f-align-center">
          {{ slotProps.item.term }}
        </div>
      </template>
      <template v-slot:items-title="slotProps" v-if="texts.includes('title')">
        <div class="display-flex f-align-center f-j-end">
          {{ slotProps.item.title }}
        </div>
      </template>
      <template v-slot:items-subtitle="slotProps" v-if="texts.includes('subtitle')">
        <div class="display-flex f-align-center f-j-end">
          {{ slotProps.item.subtitle }}
        </div>
      </template>
      <template v-slot:items-description="slotProps" v-if="texts.includes('description')">
        <div class="display-flex f-align-center f-j-end">
          {{ slotProps.item.description }}
        </div>
      </template>
      <template v-slot:items-total="slotProps">
        <div class="display-flex f-align-center f-j-end">
          {{ slotProps.item.total }}
        </div>
      </template>
      <template v-slot:items-density="slotProps">
        <div class="display-flex f-align-center f-j-end">
          {{ slotProps.item.density }}%
        </div>
      </template>
      <template v-slot:containerEnd>
        <div class="display-flex f-j-between pl-20 pr-20 pt-12 pb-12 no-select next-prev-block" v-if="tableMounted">
          <div class="cursor-pointer" :class="{active: isAllowedPageChange(this.$refs.table.pagination.currentPage - 1)}" @click="pageChanged(this.$refs.table.pagination.currentPage - 1)"><svg-icon icon="arrow-left"/><span class="ml-6">Prev</span></div>
          <div class="cursor-pointer" :class="{active: isAllowedPageChange(this.$refs.table.pagination.currentPage + 1)}" @click="pageChanged(this.$refs.table.pagination.currentPage + 1)"><span class="mr-6">Next</span><svg-icon icon="arrow-right"/></div>
        </div>
      </template>
    </SimpleInfoTable>
  </div>
</template>

<style scoped lang="scss">
.countSelector {
  cursor: pointer;
  height: 24px;
  width: 30px;
  font-size: 15px;
  font-weight: 400;
  border-radius: 4px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--neutral-500, #CAD5E5);
  color: var(--neutral-800, #505660);

  &.active {
    border: 1px solid var(--primary-default, #5D66FE);
    color: var(--primary-default, #5D66FE);
  }
}

.next-prev-block {
  border-top: 1px solid #E1E8F2;

  > div {
    display: flex;
    align-items: center;

    color: var(--neutral-600, #A3B0C5);
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;

    svg {
      margin-top: 2px;
    }

    &.active {
      color: var(--primary-default, #5D66FE);
    }
  }
}
</style>