<template>
  <div class="header-tabs-links"
       :class="classes"
       v-click-outside="outside">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "HeaderTabs",
  props: {
    classes: {
      type: String
    }
  },
  data() {
    return {
      isOpened: false,
    }
  },
  emits: ['dropdown-closed'],
  methods: {
    outside() {
      this.isOpened = false;
      this.$emit('dropdown-closed');
    },
  }
}
</script>

<style lang="scss" src="./styles.scss"></style>