<script>
export default {
  name: "ModalCommonContentContainer",
  props: {
    contentClasses: {
      type: Array,
      default: {}
    }
  }
}
</script>

<template>
<div>
  <div class="common-modal-heading mb-17">
    <slot name="heading"></slot>
  </div>
  <div class="common-modal-content" :class="contentClasses">
    <slot name="content"></slot>
  </div>
</div>
</template>

<style scoped lang="scss">
.common-modal-heading {
  color: var(--Neutral-900, #242830);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  user-select: none;
}
.common-modal-content {
  max-height: 80vh;
  max-width: 80vw;
  overflow-y: auto;
  overflow-x: hidden;

  &.noScroll {
    overflow: hidden;
  }
}
</style>