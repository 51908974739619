<script>
export default {
  name: "InitMessage"
}
</script>

<template>
  <div class="display-flex f-j-around f-align-start common-white-container ml-20 mr-20 pb-35 pt-5 pl-30 pr-30 text-blocks-container">
    <div class="text-block">
      <div>
        <div class="common-big-block-title">🚀 Benefits of Using Recommended Keywords:</div>
        <div>
          <ul>
            <li>Get <b>relevant keywords</b> from different sources and algorithms.</li>
            <li><b>Avoid using many tools and spend a lot of time</b> to gather your list of important words.</li>
            <li>All you need is just to <b>approve</b> (start tracking) or <b>decline</b> to suggested keywords.</li>
          </ul>
        </div>
      </div>
      <div>
        <div class="common-big-block-title">🚦 Getting Started with Recommendations:</div>
        <div>
          To begin receiving suggestions, <b>choose your competitors</b>.<br>
          <b>More competitors you mark - more effective recommendations you get</b>. Best practice is to check  your main niche keywords and mark top ranking apps like competitor or non competitor.<br>
          It's a straightforward process (1-2 min).
        </div>
      </div>
      <div>
        <div class="common-big-block-title">🔍 How to Find Competitors:</div>
        <div>
          Method 1️⃣ <b>Competitors Finder</b>:<br>
          <ol>
            <li>Go to the <router-link :to="{name:'CompetitorsFinder'}">Competitors Finder</router-link> and enter 2-3 main niche keywords.</li>
            <li class="mb-10">Select competitors from our recommendations.</li>
          </ol>
          <div class="common-btn-purple mb-15" @click="this.$router.push({name:'CompetitorsFinder'})">Competitor Finder</div>
          Method 2️⃣ <b>Live Search</b>:<br>
          <ol>
            <li>Check <router-link :to="{name:'LiveSearch'}">live search</router-link> for the most popular keywords in your niche.</li>
            <li>Identify and mark competitors.</li>
          </ol>
          <div class="common-btn-purple mt-10" @click="this.$router.push({name:'LiveSearch'})">Live Search</div>
        </div>
      </div>
    </div>
    <div class="text-block ml-15">
      <div class="common-big-block-title">🧩 Recommended keywords sources:</div>
      <div>
        <ol>
          <li><b>Competitors Intelligence</b> - our algorithms analyze the <b>keyword strategy of your competitors</b> to find keywords that lead to installs for them and recommend them to you.</li>
          <li><b>Ranking Intelligence</b> - checks <b>keywords that your app already ranks</b> for, finding perfect ones to work with.<br><div class="related-tool-link">Related Tools: <router-link :to="{name:'RankingKeywords'}">Ranking Keywords</router-link></div></li>
          <li><b>Metadata Intelligence</b> - inspecting <b>your app's metadata along with competitors'</b> to pinpoint keywords employed in the metadata.<br><div class="related-tool-link">Related tools: <router-link :to="{name:'MetadataKeywords'}">Metadata Keywords</router-link>, <router-link :to="{name:'Metadata'}">Metadata Analysis</router-link></div></li>
          <li><b>Related Intelligence</b> - regularly checks keywords that you already track to find new ideas using <b>our algorithms that determine keywords close to your niche</b>.<br><div class="related-tool-link">Related Tools: <router-link :to="{name:'RelatedKeywords'}">Related Keywords Finder</router-link></div></li>
          <li><b>Suggestions Intelligence - regularly checks store autosuggestion</b> for keywords that you use to determine new popular keywords to track.<br><div class="related-tool-link">Related Tools: <router-link :to="{name:'KeywordsAutosuggest'}">Keywords Autosuggestions Analyzer</router-link></div></li>
        </ol>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
ul,ol {
  margin: 0;
  padding: 0;
  padding-left: 25px;
}

li {
  margin-top: 15px;
}

a {
  text-decoration: underline;
  color: var(--primary-default) !important;
}

b {
  font-weight: 600;
}

.common-big-block-title {
  margin-bottom: 15px;
  margin-top: 35px;
  font-size: 1.5em;
}

.text-block {
  max-width: 600px;
}

.related-tool-link {
  font-style: italic;
  margin-top: 10px;
  color: var(--neutral-800);
}

@media (max-width: 1400px) {
  .text-blocks-container {
    flex-direction: column;
    align-items: center;

    > div:last-child {
      margin-top: 25px;
      border-top: 1px solid black;
    }
  }
}
</style>