<template>
  <div class="keyword-block-wrap">
    <slot name="checkbox"></slot>
    <div class="value-block">
      <span v-html="localValue"></span>
      <div class="translated-block"
           v-html="localTranslatedValue"></div>
    </div>
    <div class="actions-block">
      <slot name="actions-block"></slot>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import {splitTextToParts} from "@/utils/utils";

export default defineComponent({
  name: "KeywordTool",
  props: {
    value: {
      type: String,
    },
    translatedValue: {
      type: String,
    },
    searchInputValue: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      localValue: null,
      localTranslatedValue: null,
    }
  },
  mounted() {
    this.localValue = this.value;
    this.localTranslatedValue = this.translatedValue;

    if (this.searchInputValue !== '') {
      this.localValue = this.highLightedValue(this.value, this.searchInputValue);
    }
  },
  methods: {
    highLightedValue(value, searchInput) {
      let localKeywordArray = splitTextToParts(value);
      let resultStrArr = [];

      localKeywordArray.forEach((word) => {
        if (word === ' ') {
          resultStrArr.push("&nbsp;");
          return;
        }
        let loweredWord = word?.toLowerCase();
        let inputLoweredValue = searchInput?.toLowerCase();
        let cssClass = '';

        if (inputLoweredValue.includes(loweredWord)) {
          cssClass = 'highlighted';
        } else {
          cssClass = 'default-text';
        }

        resultStrArr.push(`<span class="${cssClass}">${word}</span>`);
      });

      return resultStrArr.join('');
    },
  },
})
</script>

<style lang="scss">
.keyword-block-wrap {
  display: flex;
  width: 100%;

  .value-block {
    display: block;
    word-break: break-word;
    padding-right: 15px;
    padding-top: 4px;
    padding-bottom: 4px;

    .highlighted {
      color: var(--neutral-900);
      font-weight: 600;
    }
  }

  .actions-block {
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 15;
    background-color: #fff;
    top: 2px;
    bottom: 2px;
    right: 16px;
    padding-left: 10px;
  }
}
</style>