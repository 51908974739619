<template>
  <div class="modal-message suggested-keywords-modal">
    <div class="modal-heading small-heading">
      <h3>{{ listTitle }} <span class="keyword">”{{ currentKeywordItem }}”</span></h3>
      <slot name="afterHeader"></slot>
    </div>

    <div class="suggested-keywords-block">
      <div class="suggested-keywords">
        <template v-if="showDataLoader">
          <preloader-table-small :loader-size="'40px'"></preloader-table-small>
        </template>
        <template v-else>
          <template v-if="suggestedKeywords?.length > 0">
            <custom-chips v-for="(chip, idx) in suggestedKeywords"
                          :key="idx"
                          @chip-deleted="removeKeyword(chip)"
                          @chip-added="saveKeyword(chip, 'add')"
                          :selectable="true"
                          :is-selected="chip.is_included || chip.selected"
                          :is-frozen="chip.frozen"
                          :amount="+chip?.sap">
              <span>{{ chip.keyword }}</span>
              <span class="translated-block" v-if="chip.translation">
                {{ chip.translation }}
              </span>
            </custom-chips>
          </template>
          <template v-else>
            <div class="no-results">
              <span>😢</span>
              <div>No keywords found.</div>
            </div>
          </template>
        </template>
      </div>

      <div v-if="!showDataLoader && suggestedKeywords?.length > 0" class="bottom-block">
        <base-button v-if="!autoSuggestedKeywordsAddedAll"
           height="40px"
           width="120px"
           :mode="'outline'"
           :classes="'add-all-button'"
           @click="saveAllKeywords('all')">
          Add All
        </base-button>
        <router-link :to="link"

                     target='_blank'
                     class="more">
          {{ linkTitle }} <svg-icon icon="arrow"/>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import CustomTextareaWithChips from "@/components/Forms/CustomTextareaWithChips/index.vue";
import CustomChips from "@/components/Forms/CustomChips/index.vue";
import PreloaderTableSmall from "@/components/UI/PreloaderTableSmall/index.vue";

export default {
  name: "AutosuggestsList",
  components: {
    'textarea-with-chips': CustomTextareaWithChips,
    'custom-chips': CustomChips,
    'preloader-table-small': PreloaderTableSmall,
  },
  props: {
    currentCountryName: String,
    currentCountryCode: String,
    currentKeywordItem: String,
    currentApp: Object,
    suggestedKeywords: Array,
    showDataLoader: Boolean,
    listTitle: String,
    linkTitle: String,
    link: String,
  },
  emits: ['save-keywords', 'remove-keyword', 'new-keyword-added'],
  methods: {
    async saveAllKeywords() {
      //this.$emit('save-keywords', suggestedKeywords, 'all');
      await this.saveKeywords(this.suggestedKeywords);
    },
    async removeKeyword(chip) {
      if (!chip.frozen && chip.id !== undefined) {
        await this.keywordsBatchDelete([chip.id], this.currentApp.id, this.currentCountryCode);
        this.$emit('remove-keyword', chip.id, chip.keyword);
      }
    },
    async saveKeywords(keywords) {
      let forSave = [];
      keywords.forEach(keyword => {
        if (!keyword.frozen) {
          forSave.push(keyword.keyword);
        }
      });

      if (forSave.length === 0) {
        return;
      }

      let response = await this.saveAddedKeywordsFunc(forSave, this.currentApp.id, this.currentCountryCode);
      if (response.saved_keywords_ids !== undefined) {
        this.$emit('new-keyword-added', response.saved_keywords_ids);
      }
    },
    async saveKeyword(chip) {
      await this.saveKeywords([chip]);
    },
  },
  computed: {
    keywordsToAddCount() {
      let toAdd = 0;
      this.suggestedKeywords.forEach(keyword => {
        if (!keyword.is_included && !keyword.selected) {
          toAdd++;
        }
      });
      return toAdd;
    },
    autoSuggestedKeywordsAddedAll() {
      return this.keywordsToAddCount === 0;
    },
  }
}
</script>

<style lang="scss" src="./styles.scss"></style>
