<template>
  {{ formatNums(value) }}
</template>

<script>
import {defineComponent} from 'vue';

export default defineComponent({
  name: "ImpressionsTool",
  props: {
    value: {
      type: Number,
    },
  },
  data() {
    return {
    }
  },
  methods: {
    formatNums(value) {
      return value?.toLocaleString('ru-RU', {maximumFractionDigits: 0});
    }
  }
})
</script>

<style scoped lang="scss">

</style>