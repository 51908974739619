export function groupsConfig(ctx) {
    const labelParserRange = (index) => {
        const parts = index.split('_');
        const min = parts[0];
        const max = parts[1];

        if (parseInt(min) === 0) {
            return `< ${max}`;
        } else {
            return `${min} - ${max}`;
        }
    };

    const labelParserRangeTops = (index) => {
        if (index === 'none') {
            return 'None';
        }

        const parts = index.split('_');
        const min = parseInt(parts[0]);
        const max = parseInt(parts[1]);

        if (min === 1 && max === 1) {
            return `Top 1`;
        } else {
            return `Top ${min} - ${max}`;
        }
    };

    const key_to_conf = {
        //TODO folders for tracking

        // 'by_folders': {
        //   name: 'By Folders',
        //   icon: 'by_group',
        //   indexToLabel: (index) => {
        //     return index
        //   },
        //   sortBy: 'count',
        // },
        'by_language': {
            name: 'By Language',
            icon: 'by_language',
            indexToLabel: (index) => {
                return index
            },
            sortBy: 'count',
        },
        'by_popularity': {
            name: 'Popularity',
            icon: 'by_pop',
            indexToLabel: labelParserRange,
            // sortBy: 'name',
        },
        'by_top_position': {
            name: 'Rank by Tops',
            icon: 'by_rank',
            indexToLabel: labelParserRangeTops,
            // sortBy: 'name',
        },
        'by_top_changes': {
            name: 'Rank by Changes',
            icon: 'by_rank_change',
            indexToLabel: (index) => {
                return index
            },
            // sortBy: 'name',
        },
    };

    let list = [];
    for (let key of Object.keys(ctx.groupsInfo)) {
        list.push({
            id: key,
            ...key_to_conf[key],
        });
    }

    return {
        list,
        onChange: (v) => {
            ctx.selectedTab = null;
            ctx.selectedGroup = v;
        },
    }
}