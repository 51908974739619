<script>
import ModalCommonContentContainer from "../ModalCommonContentContainer.vue";
import LiveSearchModal from "./index.vue";

export default {
  name: "LiveSearchGlobalModalAdapter",
  components: {LiveSearchModal, ModalCommonContentContainer},
  props: {
    country: {
      type: Object,
      required: true,
    },
    flow: {
      type: String,
      required: true,
    },
    keyword: {
      type: String,
      required: true,
    },
  },
}
</script>

<template>
  <div class="live-search-modal">
    <div class="modal">
      <LiveSearchModal
          :current-country-code="country.code"
          :current-country-name="country.name"
          :current-country-id="country.id"
          :flow="flow"
          :current-keyword-item="keyword"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.modal {
  width: 580px;
}

@media (max-width: 767px) {
  .modal {
    width: calc(100vw - 120px);
  }
}
</style>