<template>
  <keywords-header-tabs active="recommended" />

  <template v-if="competitorsCount === null">
    <progress-indicator>Check competitors count...</progress-indicator>
  </template>

  <template v-else-if="competitorsCount >= minCompetitorsCount">
    <div class="recommended-keywords">

      <div class="recommended-table-wrap">
        <div class="new-container">

          <div class="filter-tabs-block-wrap">
            <div class="filter-tabs-block">
              <div class="tab-item"
                   v-for="item in keywordsTabsArray"
                   :class="{active: currentFilterTab.title?.toLowerCase() === item.value || (currentFilterTab.title === null && item.title === 'All')}"
                   @click="tabItemClicked(item)">
                {{ item.title }}
                <div class="dynamic-block">
                  <strong>
                    {{ item.dynamicCount?.value }}
                  </strong>
                  <div class="dynamic"
                       v-if="item.dynamicCount?.dynamic > 0"
                       :class="[{red: item.dynamicCount?.dynamic < 0}, {green: item.dynamicCount?.dynamic > 0}]">
                    <template v-if="item.dynamicCount?.dynamic > 0">+</template>
                    <span>{{ item.dynamicCount?.dynamic }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <filters-dropdown :classes="['filters-tabs-mobile']"
                            :dataUpdated="filtersCollapsedCounter">
            <template v-slot:header>{{ currentFilterTab.title }}</template>
            <template v-slot:accordion-body>
              <div class="filter-tabs-block-mobile">
                <div class="tab-item"
                     v-for="item in keywordsTabsArray"
                     :class="{active: currentFilterTab.value === item.value}"
                     @click="tabItemClicked(item)">
                  {{ item.title }}

                  <div class="dynamic-block">
                    <strong>
                      {{ item.dynamicCount?.value }}
                    </strong>
                    <div class="dynamic"
                         v-if="item.dynamicCount?.dynamic > 0"
                         :class="[{red: item.dynamicCount?.dynamic < 0}, {green: item.dynamicCount?.dynamic > 0}]">
                      <template v-if="item.dynamicCount?.dynamic > 0">+</template>
                      <span>{{ item.dynamicCount?.dynamic }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </filters-dropdown>

          <recommended-table
              :key="currentApp.id + currentCountry.code + currentFilterTab.value"
              :current-country="currentCountry"
              :current-app="currentApp"
              :filter-tab="currentFilterTab.value"

              @tracking-keywords-changed="onTrackingChanged"
          >
          </recommended-table>
        </div>
      </div>
    </div>
  </template>

  <template v-else>
    <init-message/>
  </template>

</template>

<script>
import {mapGetters} from "vuex";
import {recommendedKeywordsTableConfig} from "@/configs/page-tables-configs/recommended-keywords";
import {recommendedKeywordsGPTableConfig} from "@/configs/page-tables-configs/recommended-keywords-gp";
import ProgressIndicator from "@/components/UI/ProgressIndicator/index";
import InitMessage from "./InitMessage.vue";
import customCompetitors from "../../../api/modules/custom-competitors";
import RecommendedTable from "./RecommendedTable.vue";
import HeaderTabs from "../../../components/UI/HeaderTabs/index.vue";
import FiltersDropdown from "../../../components/Dropdowns/FiltersDropdown/index.vue";
import KeywordsHeaderTabs from "@/views/SearchTerms/components/KeywordsHeaderTabs/index.vue";
import {MIN_COMPETITORS_FOR_RECOMMENDED} from "@/sidebar-menu/counters";

export default {
  name: 'RecommendedKeywords',
  components: {
    KeywordsHeaderTabs,
    FiltersDropdown,
    HeaderTabs,
    RecommendedTable,
    InitMessage,
    'progress-indicator': ProgressIndicator,
  },
  data() {
    return {
      competitorsCount: null,
      title: '',
      loaderText: '',
      tableIsLoaded: false,
      keywordsTabsArray: [
        {
          title: 'Pending',
          value: 'pending',
          dynamicCount: null,
        },
        {
          title: 'Declined',
          value: 'declined',
          dynamicCount: null,
        },
      ],
      currentFilterTab: {
        value: 'pending',
        title: 'Pending'
      },
      tabDropdownOpened: false,
      tableIsLoading: false,
      showKeywordsLoader: false,
    }
  },
  methods: {
    async tabItemClicked(item) {
      this.currentFilterTab = {...item};
    },
    openPlansModal() {
      this.$store.dispatch('INVOKE_USER_LIMITS_MANUAL');
    },
    upgradeNowClicked() {
      this.$store.dispatch('INVOKE_USER_LIMITS_MANUAL');
    },
    async onTrackingChanged() {
      this.$store.dispatch('UPDATE_USER_LIMITS');
      this.$store.dispatch('keywordsTracking/SET_TRACKING_KEYWORDS_COUNT');
      await this.fetchDataCounts();
    },
    async fetchCompetitorsCount() {
      this.competitorsCount = (await customCompetitors.fetchCompetitors(this.currentApp.id, this.currentCountry.id, this.isCompetitors)).getCompetitors().length;
    }
  },
  async mounted() {
    await this.fetchCompetitorsCount();

    this.currentCountryCode = this.currentCountry?.code;
    this.headers = this.currentApp?.store?.key === 'APP_STORE' ? [...recommendedKeywordsTableConfig.headers] : [...recommendedKeywordsGPTableConfig.headers];

    await this.fetchDataCounts();

    this.tableIsLoaded = true;
  },
  computed: {
    ...mapGetters([
      'currentApp',
      'currentCountry',
      'promiseSettledData',
      'dataCounts',
      'dataCountsLoaded',
      'userSubscription',
    ]),
    minCompetitorsCount() {
      return MIN_COMPETITORS_FOR_RECOMMENDED;
    }
  },
}
</script>

<style lang="scss" src="./styles.scss" scoped></style>