<script>
import AutosuggestsModal from "./index.vue";
import ModalCommonContentContainer from "../ModalCommonContentContainer.vue";
import RefsStore from "../../../utils/RefsStore";

export default {
  name: "AutosuggestsGlobalModalAdapter",
  components: {ModalCommonContentContainer, AutosuggestsModal},
  props: {
    country: {
      type: Object,
      required: true,
    },
    keyword: {
      type: String,
      required: true,
    },
    app: {
      type: Object,
      required: true,
    },

    saveKeywordsCallback: {
      type: Function,
      required: false,
      default: () => {},
    },
    keywordsChangedCallback: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  methods: {
    closeModal() {
      RefsStore.getRef('globalModal').close()
    },
  },
}
</script>

<template>
  <ModalCommonContentContainer>
    <template v-slot:content>
      <div class="add-autosuggest-modal">
        <div class="modal">
          <autosuggests-modal :current-country-code="country.code"
                              :current-country-name="country.name"
                              :current-keyword-item="keyword"
                              :current-app="app"
                              @save-keywords="saveKeywordsCallback"
                              @keywords-changed="keywordsChangedCallback"
                              @closeModal="closeModal"/>
        </div>
      </div>
    </template>
  </ModalCommonContentContainer>
</template>

<style scoped lang="scss">
.modal {
  max-width: initial !important;
}
</style>