import {getSortedObjectPropsByValue} from "../../../utils/utils";

export function popularityFilter (ctx, sapProp = 'popularity') {
    return {
        type: 'range',
        title: 'Popularity',
        filtering: sapProp,
        filter: 'range_sap',

        ...(() => {
            if (ctx.selectedGroup?.id === 'by_popularity' && ctx.selectedTab) {
                return {
                    min: parseRangeTabIndexToMinMax(ctx.selectedTab).min,
                    max: parseRangeTabIndexToMinMax(ctx.selectedTab).max,
                    minLimit: parseRangeTabIndexToMinMax(ctx.selectedTab).min,
                    maxLimit: parseRangeTabIndexToMinMax(ctx.selectedTab).max,
                    isAlwaysChanged: true,
                };
            } else if (ctx.selectedGroup?.id === 'by_language' && ctx.selectedTab === 'Other') {
                return {
                    min: 6,
                    max: 100,
                    minLimit: 6,
                    maxLimit: 100,
                    isAlwaysChanged: true,
                };
            } else {
                return {
                    min: 0,
                    max: 100,
                    minLimit: 0,
                    maxLimit: 100,
                };
            }
        })(),
    };
}

export function rankFilter (ctx) {
    return {
        type: 'range',
        title: 'App Rank',
        filtering: (item) => {
            return item.last_rank_info?.rank ?? 0;
        },
        filter: 'range_rank',
        ...(
            ctx.selectedGroup?.id === 'by_top_position' && ctx.selectedTab ? {
                min: parseRangeTabIndexToMinMax(ctx.selectedTab).min,
                max: parseRangeTabIndexToMinMax(ctx.selectedTab).max,
                minLimit: parseRangeTabIndexToMinMax(ctx.selectedTab).min,
                maxLimit: parseRangeTabIndexToMinMax(ctx.selectedTab).max,
                isAlwaysChanged: true,
            } : {
                min: 0,
                max: 50,
                minLimit: 0,
                maxLimit: 50,
            }
        ),
    };
}

export function estDailyImprFilter (ctx, impressionProp = 'impression') {
    return {
        type: 'range',
        title: 'Est. Daily Impr.',
        filtering: impressionProp,
        filter: 'range_impression',
        min: 0,
        max: 9999999,
    };
}

export function difficultyFilter (ctx) {
    return {
        type: 'range',
        title: 'Difficulty',
        filtering: 'difficulty',
        filter: 'range_difficulty',
        min: 0,
        max: 100,
    };
}

export function compInTop10Filter (ctx) {
    return {
        type: 'range',
        title: 'Comp. in Top 10',
        filtering: (item) => {
            return item.top_competitors.competitors_count ?? 0;
        },
        filter: 'range_top_competitors',
        min: 0,
        max: 10,
    };
}

export function nonCompInTop10Filter (ctx) {
    return {
        type: 'range',
        title: 'NonComp. in Top 10',
        filtering: (item) => {
            return item.top_competitors.non_competitors_count ?? 0;
        },
        filter: 'range_top_non_competitors',
        min: 0,
        max: 10,
    };
}

export function wordsCountFilter (ctx) {
    return {
        type: 'radio',
        title: 'Words count',
        filtering: 'words_count',
        filter: 'range_words_count',
        value: 0,
        min: 0,
        max: 25,
    };
}

export function rankTrendFilter (ctx) {
    return {
        type: 'dropdown-multi',
        title: 'Rank trend',
        hidden: true,
        filtering: (item) => {
            const diff = item.last_rank_info.difference ?? 0;
            if (diff > 0) {
                return 'up';
            } else if (diff < 0) {
                return 'down';
            } else {
                return diff;
            }
        },
        filter: 'trending_list',
        ...(
            ctx.selectedGroup?.id === 'by_top_changes' && ctx.selectedTab ? {
                presetsData: [
                    {
                        filterValue: ctx.selectedTab.replace('new', 'in'),
                        presetValue: ctx.selectedTab,
                    },
                ],
                isAlwaysChanged: true,
            } : {
                presetsData: [
                    {
                        filterValue: 'up',
                        presetValue: 'Up',
                    },
                    {
                        filterValue: 'down',
                        presetValue: 'Down',
                    },
                    {
                        filterValue: 'in',
                        presetValue: 'New',
                    },
                    {
                        filterValue: 'out',
                        presetValue: 'Out',
                    }
                ],
            }
        ),
    };
}

export function languageFilter (ctx) {
    return {
        type: 'dropdown-multi',
        title: 'Language',
        filtering: (item) => {
            return item.language?.name ?? 'und';
        },
        filter: 'language',
        ...(() => {
            if (ctx.selectedGroup?.id === 'by_language' && ctx.selectedTab) {
                if (ctx.selectedTab === 'Other') {
                    return {
                        presetsData: Object.keys(ctx.groupsInfo.by_language).filter((item) => {
                            return item.includes('||Other');
                        }).map((lang) => {
                            lang = lang.replace('||Other', '');

                            return {
                                filterValue: lang,
                                presetValue: lang,
                            };
                        }),
                        isAlwaysChanged: true,
                    };
                }
                return {
                    presetsData: [
                        {
                            filterValue: ctx.selectedTab.replace('||Other', ''),
                            presetValue: ctx.selectedTab.replace('||Other', ''),
                        },
                    ],
                    isAlwaysChanged: true,
                };
            } else {
                return {
                    presetsData: getSortedObjectPropsByValue(ctx.groupsInfo.by_language ?? []).map((lang) => {
                        lang = lang.replace('||Other', '');

                        return {
                            filterValue: lang,
                            presetValue: lang,
                        };
                    }),
                };
            }
        })(),
    };
}

function parseRangeTabIndexToMinMax(index) {
    if (index === 'none') {
        return {
            min: 0,
            max: 0,
        };
    }

    const parts = index.split('_');
    const min = parts[0];
    const max = parts[1];

    return {
        min,
        max,
    };
}