import Tooltips from "../tooltips";

export const recommendedKeywordsTableConfig = {
    headers: [
        {
            text: 'Keywords',
            sortable: true,
            isActive: true,
            isHideable: false,
            value: 'keyword',
            sortBy: 'keyword',
            align: 'left',
            hasCheckbox: true,
            hasExtendedHeader: true,
            headingClass: 'keywords-column',
            sortArrowsAlign: 'right',
        },
        {
            text: 'Popularity',
            sortable: true,
            isActive: true,
            isHideable: true,
            value: 'sap',
            sortBy: 'sap',
            align: 'right',
            headingClass: 'sap-column',
            tooltipWidth: '280px',
            tooltipDirection: 'left',
            tooltipText: Tooltips.SAP_AS_TOOLTIP
        },
        {
            text: 'Daily Impr',
            value: 'impression',
            sortBy: 'impression',
            align: 'right',
            sortable: true,
            isActive: true,
            isHideable: true,
            headingClass: 'impressions-column',
            tooltipWidth: '300px',
            tooltipDirection: 'left',
            tooltipText: 'Estimated Daily Impressions shows the number of impressions<br> ' +
                'a keyword receives each day within a given country.'
        },
        {
            text: 'Difficulty',
            value: 'difficulty',
            sortBy: 'difficulty',
            align: 'right',
            sortable: true,
            isActive: true,
            isHideable: true,
            headingClass: 'difficulty-column',
            tooltipWidth: '248px',
            tooltipDirection: 'left',
            tooltipText: 'The Keyword Difficulty 0-100 indicates the level of competition for a keyword.<br> ' +
                'The higher the difficulty, the greater the competition for this keyword.',
        },
        {
            text: 'App Rank',
            value: 'rank',
            sortBy: 'rank',
            align: 'right',
            sortable: true,
            isActive: true,
            isHideable: true,
            headingClass: 'rank-column',
            tooltipWidth: '248px',
            tooltipDirection: 'right',
            tooltipText: 'The current position of your app for the given keyword.'
        },
        {
            text: 'Results',
            value: 'applications_count',
            sortBy: 'applications_count',
            align: 'right',
            sortable: true,
            isActive: true,
            isHideable: true,
            headingClass: 'results-column',
            tooltipWidth: '248px',
            tooltipDirection: 'right',
            tooltipText: 'The number of apps found for this keyword.'
        },
        {
            text: 'Comp. in Top 10',
            value: 'competitors_top',
            sortBy: 'top_competitors',
            align: 'right',
            sortable: true,
            isActive: true,
            isHideable: true,
            headingClass: 'comp-top-column',
            tooltipWidth: '248px',
            tooltipDirection: 'right',
            tooltipText: 'This indicates the number of competitors within the top 10 search results for a keyword. A higher number of competitors in the top 10 results – the keyword is more relevant.'
        },
        {
            text: 'Source',
            value: 'source',
            sortBy: 'source',
            align: 'left',
            sortable: false,
            isActive: true,
            isHideable: true,
            headingClass: 'source-column'
        },
    ],
}