<script>
import {splitTextToParts} from "../../../utils/utils";

export default {
  name: "KeywordToolNew",
  props: {
    value: {
      type: String,
    },
    translatedValue: {
      type: String,
    },
    searchInputValue: {
      type: String,
      required: false,
    },

    showByTriggerStyles: {
      type: Object,
      required: false,
    },

    isTracked: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      greenBlink: false,
      redBlink: false,
    };
  },
  computed: {
    valueToDisplay() {
      if (this.searchInputValue !== '') {
        return this.highLightedValue(this.value, this.searchInputValue);
      } else {
        return this.value;
      }
    },
  },
  methods: {
    highLightedValue(value, searchInput) {
      const lower = value.toLowerCase();
      const searchInputLower = searchInput.toLowerCase();

      const from = lower.indexOf(searchInputLower);
      const to = from + searchInputLower.length;

      return value.substring(0, from) + '<span class="highlighted">' + value.substring(from, to) + '</span>' + value.substring(to, value.length);
    },

    blinkGreen() {
      this.greenBlink = true;
      setTimeout(() => {
        this.greenBlink = false;
      }, 300);
    },
    blinkRed() {
      this.redBlink = true;
      setTimeout(() => {
        this.redBlink = false;
      }, 300);
    },
  },
  watch: {
    // isTracked(newV, old) {
    //   if (old === null || newV === old) {
    //     return;
    //   }
    //
    //   if (newV === true) {
    //     this.blinkGreen();
    //   } else if (newV === false) {
    //     this.blinkRed();
    //   }
    // },
  },
}
</script>

<template>
<div class="display-flex f-j-between w100p hoverTrigger keywordToolWrap" :class="{'greenBlink': greenBlink, 'redBlink': redBlink}">
  <div>
    <div v-html="valueToDisplay"></div>
    <div v-if="translatedValue" class="translated-block">{{translatedValue}}</div>
  </div>

  <div>
    <slot name="endOfNameSection"></slot>
  </div>

  <div class="showByTrigger" :style="showByTriggerStyles">
    <slot name="hoverSection"></slot>
  </div>
</div>
</template>

<style scoped lang="scss">
.hoverTrigger {
  position: relative;

  .showByTrigger {
    opacity: 0;
    transition: 0.1s;

    position: absolute;
    right: 0;
    height: 100%;
    display: flex;
    padding-left: 15px;
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 10%, rgba(255,255,255,1) 100%);
  }

  &:hover {
    .showByTrigger {
      opacity: 1;
    }
  }
}

.translated-block {
  color: var(--neutral-700);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.greenBlink {
  animation: greenBlink 0.3s linear;
}
.redBlink {
  animation: redBlink 0.3s linear;
}
@keyframes greenBlink {
  0% {
    background-color: #fff;
  }
  50% {
    background: radial-gradient(circle, rgba(93,202,130, 0.2) 0%, rgba(93,202,130,0.2) 90%, rgba(255,255,255,0) 100%);
  }
  100% {
    background-color: #fff;
  }
}
@keyframes redBlink {
  0% {
    background-color: #fff;
  }
  50% {
    background: radial-gradient(circle, rgba(253,117,112, 0.2) 0%, rgba(253,117,112,0.2) 90%, rgba(255,255,255,0) 100%);
  }
  100% {
    background-color: #fff;
  }
}
</style>
<style>
.keywordToolWrap .highlighted {
  color: var(--neutral-900);
  font-weight: 600;
}
</style>