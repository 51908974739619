<template>
  <div class="competitors_in_top_block" @click="$emit('clicked')" v-tooltip="{ text: 'Show top', position: tooltipPos, classes: ['no-wrap-text'] }">
    <div class="progress-wrap competitors_in_top">
      <progress-bar :width="'100%'"
                    height="8px"
                    :fill-color="color"
                    bar-type="custom"/>
    </div>
    <div class="value-block">
      <span class="green">{{ competitors }}</span>
      <span>/</span>
      <span class="yellow">{{ indirectCompetitors }}</span>
      <span>/</span>
      <span class="red">{{ nonCompetitots }}</span>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import ProgressBar from "@/components/UI/ProgressBar/index.vue";

export default defineComponent({
  name: "CompetitorsInTopTool",
  components: {
    ProgressBar,
  },
  props: {
    total: {
      type: Number,
    },
    competitors: {
      type: Number,
    },
    nonCompetitots: {
      type: Number,
    },
    indirectCompetitors: {
      type: Number,
    },
    tooltipPos: {
      type: String,
      default: 'bottom'
    }
  },
  emits: ['clicked'],
  data() {
    return {}
  },
  computed: {
    color() {
      let green = this.competitors / this.total * 100;
      let yellow = this.indirectCompetitors / this.total * 100;
      let red = this.nonCompetitots / this.total * 100;

      yellow += green;
      red += yellow;

      return `background: linear-gradient(90deg, #5AAC80 ${green}%, #F7D758 ${green}%, #F7D758 ${yellow}%, #EC5C3E ${yellow}%, #EC5C3E ${red}%, #E1E8F2 ${red}%);`;
    }
  },
  methods: {
  }
})
</script>

<style scoped lang="scss">
.competitors_in_top_block {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;

  .green {
    color: var(--secondary-dark-green);
  }

  .red {
    color: var(--secondary-red);
  }
}
.progress-wrap.competitors_in_top {
  width: 42px !important;
  cursor: pointer;
}
.value-block {
  white-space: nowrap;
  margin-left: 8px;
  min-width: 19px;
}
</style>