<template>
  <div class="progress-wrap">
    <progress-bar v-if="value"
                  :width="value + '%'"
                  height="8px"
                  :fill-color="`background: ${calculateColor(value)}`"
                  bar-type="custom"/>
  </div>
  <div class="value-block">{{ value ?? '-' }}</div>
</template>

<script>
import {defineComponent} from 'vue';
import SapChartModal from "@/components/Modals/SapChartModal/index.vue";
import ProgressBar from "@/components/UI/ProgressBar/index.vue";
import {colorForProgressBar} from "@/utils/utils";

export default defineComponent({
  name: "DifficultyTool",
  components: {
    'sap-chart-modal': SapChartModal,
    ProgressBar,
  },
  props: {
    value: {
      type: Number,
    },
  },
  data() {
    return {
      showTableChartModal: false,
    }
  },
  methods: {
    popularityChartClicked() {
      this.openModal('showTableChartModal');
    },
    calculateColor(count) {
      return colorForProgressBar(count);
    },
  }
})
</script>

<style scoped lang="scss">
.chart-icon {
  font-size: 24px;
  color: #a3b0c5;
  margin-left: 2px;
  cursor: pointer;
}

.progress-wrap {
  width: 42px;
}

.value-block {
  white-space: nowrap;
  margin-left: 8px;
  min-width: 19px;
}
</style>