<template>
  <div class="keywords-header-tabs">
    <div class="new-container" v-if="isMobile">
      <header-tabs @dropdown-closed="tabDropdownOpened = false">
        <div class="mobile-tabs-content"
             :class="{active: tabDropdownOpened}">
          <div class="mobile-tabs-heading">
            <div class="active-tab">
              <span class="text">{{ mobileTitle }}</span>
              <div class="value-block"
                   @click="tabDropdownOpened = !tabDropdownOpened">
                <div class="value">
                  {{ dataCounts?.trackingKeywords?.total?.value }}
                </div>

                <div class="dynamics"
                     :class="dataCounts?.trackingKeywords?.total?.dynamicCssClass">
                  {{ dataCounts?.trackingKeywords?.total?.dynamic }}
                </div>
              </div>
              <div class="tab-icon"
                   @click="tabDropdownOpened = !tabDropdownOpened">
                <img src="@/assets/svg/arrow.svg" alt="Arrow icon">
              </div>
            </div>
            <div class="add-keyword-btn"
                 v-if="active === 'tracking'"
                 @click="openKeywordsAddModal">
              Add keywords
            </div>
          </div>
          <div class="mobile-tabs-list">
            <router-link class="tab-item"
                         :class="{'active': active === 'tracking'}"
                         tag="div"
                         to="/search-terms/keywords-tracking">
              <div class="text">Tracking</div>
              <div class="value-block">
                <div class="value">
                  {{ dataCounts?.trackingKeywords?.total?.value }}
                </div>

                <div class="dynamics"
                     :class="dataCounts?.trackingKeywords?.total?.dynamicCssClass">
                  {{ dataCounts?.trackingKeywords?.total?.dynamic }}
                </div>
              </div>
            </router-link>

            <router-link class="tab-item"
                         :class="{'active': active === 'recommended'}"
                         tag="div"
                         :to="{name: 'RecommendedKeywords'}">
              <div class="text">Recommended</div>
              <div class="value-block">
                <div class="value" v-if="showRecommendedCounter">
                  {{ dataCounts?.recommendedKeywords?.pending?.value }}
                </div>

                <div class="dynamics"
                     v-if="dataCounts?.recommendedKeywords?.pending?.dynamic > 0 && showRecommendedCounter"
                     :class="dataCounts?.recommendedKeywords?.pending?.dynamicCssClass">
                  {{ dataCounts?.recommendedKeywords?.pending?.dynamic }}
                </div>
              </div>
            </router-link>
            <router-link class="tab-item"
                         :class="{'active': active === 'metadata'}"
                         tag="div"
                         :to="{name: 'MetadataKeywords'}">
              <div class="text">Metadata</div>
              <div class="value-block">
                <div class="value">
                  {{ dataCounts?.metaKeywords?.total?.value }}
                </div>

                <div class="dynamics"
                     :class="dataCounts?.metaKeywords?.total?.dynamicCssClass">
                  {{ dataCounts?.metaKeywords?.total?.dynamic }}
                </div>
              </div>
            </router-link>
            <router-link class="tab-item"
                         :class="{'active': active === 'ranking'}"
                         tag="div"
                         :to="{name: 'RankingKeywords'}">
              <div class="text">Ranking</div>
              <div class="value-block">
                <div class="value">
                  {{ dataCounts?.rankingKeywords?.all?.value }}
                </div>

                <div class="dynamics"
                     :class="dataCounts?.rankingKeywords?.all?.dynamicCssClass">
                  {{ dataCounts?.rankingKeywords?.all?.dynamic }}
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </header-tabs>

      <div class="mb-24">
        <order-radaso-button :pre-selected="['keywords-research']" />
      </div>
    </div>
    <template v-else-if="isTablet">
      <header-tabs @dropdown-closed="tabDropdownOpened = false"
                   classes="tablet-tabs">
        <div class="mobile-tabs-content"
             :class="{'active': tabDropdownOpened}">
          <div class="mobile-tabs-heading">
            <div class="active-tab">
              <span class="text">{{ mobileTitle }}</span>
              <div class="value-block"
                   @click="tabDropdownOpened = !tabDropdownOpened">
                <div class="value">
                  {{ dataCounts?.trackingKeywords?.total?.value }}
                </div>
                <div class="dynamics"
                     :class="dataCounts?.trackingKeywords?.total?.dynamicCssClass">
                  {{ dataCounts?.trackingKeywords?.total?.dynamic }}
                </div>
              </div>
              <div class="add-keyword-btn"
                   v-if="active === 'tracking'"
                   @click="openKeywordsAddModal">
                Add keywords
              </div>
              <div class="tab-icon"
                   @click="tabDropdownOpened = !tabDropdownOpened">
                <img src="@/assets/svg/arrow.svg" alt="Arrow icon">
              </div>
            </div>
          </div>
          <div class="mobile-tabs-list">
            <router-link class="tab-item"
                         :class="{'active': active === 'tracking'}"
                         tag="div"
                         to="/search-terms/keywords-tracking">
              <div class="text">Tracking</div>
              <div class="value-block">
                <div class="value">
                  {{ dataCounts?.trackingKeywords?.total?.value ?? 0 }}
                </div>

                <div class="dynamics"
                     :class="dataCounts?.trackingKeywords?.total?.dynamicCssClass ?? ''">
                  {{ dataCounts?.trackingKeywords?.total?.dynamic ?? '' }}
                </div>
              </div>
            </router-link>
            <router-link class="tab-item"
                         :class="{'active': active === 'recommended'}"
                         tag="div"
                         :to="{name: 'RecommendedKeywords'}">
              <div class="text">Recommended</div>
              <div class="value-block">
                <div class="value" v-if="showRecommendedCounter">
                  {{ dataCounts?.recommendedKeywords?.pending?.value }}
                </div>

                <div class="dynamics"
                     v-if="dataCounts?.recommendedKeywords?.pending?.dynamic > 0 && showRecommendedCounter"
                     :class="dataCounts?.recommendedKeywords?.pending?.dynamicCssClass">
                  {{ dataCounts?.recommendedKeywords?.pending?.dynamic }}
                </div>
              </div>
            </router-link>
            <router-link class="tab-item"
                         :class="{'active': active === 'metadata'}"
                         tag="div"
                         :to="{name: 'MetadataKeywords'}">
              <div class="text">Metadata</div>
              <div class="value-block">
                <div class="value">
                  {{ dataCounts?.metaKeywords?.total?.value }}
                </div>

                <div class="dynamics"
                     :class="dataCounts?.metaKeywords?.total?.dynamicCssClass">
                  {{ dataCounts?.metaKeywords?.total?.dynamic }}
                </div>
              </div>
            </router-link>
            <router-link class="tab-item"
                         :class="{'active': active === 'ranking'}"
                         tag="div"
                         :to="{name: 'RankingKeywords'}">
              <div class="text">Ranking</div>
              <div class="value-block">
                <div class="value">
                  {{ dataCounts?.rankingKeywords?.all?.value }}
                </div>

                <div class="dynamics"
                     :class="dataCounts?.rankingKeywords?.all?.dynamicCssClass">
                  {{ dataCounts?.rankingKeywords?.all?.dynamic }}
                </div>
              </div>
            </router-link>
          </div>
        </div>

        <order-radaso-button :pre-selected="['keywords-research']" />
      </header-tabs>
    </template>

    <div v-else class="header-tabs-links desktop-tabs">
      <div class="tabs-content">
        <router-link class="tab-item"
                     :class="{'active': active === 'tracking'}"
                     tag="div"
                     to="/search-terms/keywords-tracking">
          <div class="text">Tracking</div>
          <div class="value-block">
            <div class="value">
              {{ dataCounts?.trackingKeywords?.total?.value ?? 0 }}
            </div>

            <div class="dynamics"
                 :class="dataCounts?.trackingKeywords?.total?.dynamicCssClass ?? ''">
              {{ dataCounts?.trackingKeywords?.total?.dynamic ?? '' }}
            </div>

            <div class="add-keyword-btn"
                 v-if="active === 'tracking'"
                 @click="openKeywordsAddModal">
              Add keywords
            </div>
          </div>
        </router-link>

        <router-link class="tab-item"
                     :class="{'active': active === 'recommended'}"
                     tag="div"
                     :to="{name: 'RecommendedKeywords'}">
          <div class="text">Recommended</div>
          <div class="value-block">
            <div class="value" v-if="showRecommendedCounter">
              {{ dataCounts?.recommendedKeywords?.pending?.value }}
            </div>

            <div class="dynamics"
                 v-if="dataCounts?.recommendedKeywords?.pending?.dynamic > 0 && showRecommendedCounter"
                 :class="dataCounts?.recommendedKeywords?.pending?.dynamicCssClass">
              {{ dataCounts?.recommendedKeywords?.pending?.dynamic }}
            </div>
          </div>
        </router-link>

        <router-link class="tab-item"
                     :class="{'active': active === 'metadata'}"
                     tag="div"
                     to="/search-terms/metadata-keywords">
          <div class="text">Metadata</div>
          <div class="value-block">
            <div class="value">
              {{ dataCounts?.metaKeywords?.total?.value }}
            </div>

            <div class="dynamics"
                 :class="dataCounts?.metaKeywords?.total?.dynamicCssClass">
              {{ dataCounts?.metaKeywords?.total?.dynamic }}
            </div>
          </div>
        </router-link>

        <router-link class="tab-item"
                     :class="{'active': active === 'ranking'}"
                     tag="div"
                     to="/search-terms/ranking-keywords">
          <div class="text">Ranking</div>
          <div class="value-block">
            <div class="value">
              {{ dataCounts?.rankingKeywords?.all?.value }}
            </div>

            <div class="dynamics"
                 :class="dataCounts?.rankingKeywords?.all?.dynamicCssClass">
              {{ dataCounts?.rankingKeywords?.all?.dynamic }}
            </div>
          </div>
        </router-link>
      </div>

      <order-radaso-button :pre-selected="['keywords-research']" />
    </div>
  </div>
</template>
<script>
import OrderRadasoButton from "@/components/OrderRadaso/Button/index.vue";
import SvgIcon from "@/components/UI/SvgIcon/index.vue";
import {mapGetters} from "vuex";
import HeaderTabs from "@/components/UI/HeaderTabs/index.vue";
import {MIN_COMPETITORS_FOR_RECOMMENDED} from "@/sidebar-menu/counters";

export default {
  name: 'KeywordsHeaderTabs',
  components: {
    HeaderTabs,
    SvgIcon,
    OrderRadasoButton
  },
  props: {
    active: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      tabDropdownOpened: false,
    };
  },
  methods: {
    openKeywordsAddModal() {
      this.$emit('add-keyword');
    },
  },
  computed: {
    ...mapGetters([
      'isMobile',
      'isTablet',
      'dataCounts',
      'dataCountsLoaded',
    ]),
    mobileTitle() {
      return this.active.charAt(0).toUpperCase() + this.active.slice(1);
    },
    minCompetitorsCount() {
      return MIN_COMPETITORS_FOR_RECOMMENDED;
    },
    showRecommendedCounter() {
      return this.dataCounts?.competitors?.selectedCount >= this.minCompetitorsCount;
    }
  },
}
</script>
<style lang="scss">
.keywords-header-tabs {
  .header-tabs-links.desktop-tabs {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header-tabs-links.tablet-tabs {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>