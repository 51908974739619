export function checkedItems(ctx) {
    return ctx.items.filter((item) => {
        return item.checked;
    });
}

export function allTranslated(ctx) {
    return ctx.$refs.table.preparedData.every((item) => {
        return item.translate;
    });
}

export function isShowTableBlur(ctx) {
    return ctx.userSubscription?.plan_name === 'Free' && ctx.total > 15;
}