<template>
  <div class="result"
        v-html="formatNums(value)">
  </div>
</template>

<script>
import {defineComponent} from 'vue';

export default defineComponent({
  name: "ResultsTool",
  props: ['value'],
  data() {
    return {}
  },
  methods: {
    formatNums(value) {
      if (typeof value === 'string' && value?.includes('+')) {
        return `<span style="display: block;transform: translateX(10px)">${value}</span>`;
      }
      if (value === null) {
        return '-';
      }

      let numVal = Number(value);

      return numVal?.toLocaleString('ru-RU', {maximumFractionDigits: 0});
    }
  }
})
</script>

<style scoped lang="scss">

</style>