<script>
import SapChartModal from "./index.vue";
import ModalCommonContentContainer from "../ModalCommonContentContainer.vue";

export default {
  name: "SapChartGlobalModalAdapter",
  components: {ModalCommonContentContainer, SapChartModal},
  props: {
    country: {
      type: Object,
      required: true,
    },
    keyword: {
      type: String,
      required: true,
    },
  },
}
</script>

<template>
  <ModalCommonContentContainer :content-classes="['noScroll']">
    <template v-slot:content>
      <div class="popularity-chart-modal">
        <div class="modal">
          <sap-chart-modal :current-country-code="country.code"
                           :current-country-name="country.name"
                           :current-keyword-item="keyword"/>
        </div>
      </div>
    </template>
  </ModalCommonContentContainer>
</template>

<style scoped lang="scss">

</style>