<script>
export default {
  name: "UnlockFullTableBlur",
  methods: {
    upgradeNowClicked() {
      this.$store.dispatch('INVOKE_USER_LIMITS_MANUAL');
    },
  }
}
</script>

<template>
  <div class="get-full-access-block">

    <div class="title">
      To access the full list of keywords, please upgrade
    </div>
    <div class="subtitle">
      Unlock the full potential of our keyword ranking tool and find the best keywords to optimize your
      app's
      visibility and drive more downloads.
    </div>
    <div class="btn-block">
      <base-button height="40px"
                   width="160px"
                   font-size="15px"
                   border-radius="4px"
                   @click="upgradeNowClicked">Upgrade Now
      </base-button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.get-full-access-block {
  padding: 28px 20px 25px;
  position: absolute;
  z-index: 100;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  background-color: rgba(255, 255, 255, 0);
  height: calc(48px * 4);

  @media only screen and (max-width: 1450px) {
    width: 1200px;
  }

  .title {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    line-height: 1.2;
    color: var(--title-color);
    margin-bottom: 8px;

    @media only screen and (max-width: 991px) {
      font-size: 18px;
      margin-bottom: 6px;
    }

    @media only screen and (max-width: 767px) {
      font-size: 16px;
    }
  }

  .subtitle {
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    color: #4d5874;
    max-width: 710px;
    margin: 0 auto 20px;

    @media only screen and (max-width: 991px) {
      font-size: 15px;
      margin-bottom: 16px;
    }

    @media only screen and (max-width: 767px) {
      font-size: 14px;
      margin-bottom: 12px;
    }
  }

  .btn {
    width: 160px;
    font-weight: 600;
  }
}
</style>