<template>
  <autosuggests-list :current-app="currentApp"
                     :current-country-code="currentCountryCode"
                     :current-country-name="currentCountryName"
                     :current-keyword-item="currentKeywordItem"
                     :suggested-keywords="suggestedKeywords"
                     :show-data-loader="showSuggestionsDataLoader"
                     :list-title="'Autosuggestions for'"
                     :link-title="'Show more Autosuggestions'"
                     :link="'/search-terms/keywords-autosuggest?keyword=' + currentKeywordItem"
                     @remove-keyword="removeKeyword"
                     @new-keyword-added="newKeywordAdded">
    <template v-slot:afterHeader>
      <translate-tool :active="showTranslation"
                      class="left-tooltip"
                      @translate-clicked="translateClickHandler"
      />
    </template>
  </autosuggests-list>
  <autosuggests-list :current-app="currentApp"
                     :current-country-code="currentCountryCode"
                     :current-country-name="currentCountryName"
                     :current-keyword-item="currentKeywordItem"
                     :suggested-keywords="relatedKeywords"
                     :show-data-loader="showRelatedDataLoader"
                     :list-title="'Related to'"
                     :link-title="'Show more Related Keywords'"
                     :link="'/search-terms/related-keywords?keyword=' + currentKeywordItem"
                     @remove-keyword="removeKeyword"
                     @new-keyword-added="newKeywordAdded"
  />
  <div class="close-button">
    <base-button height="40px"
                 width="100px"
                 :mode="'outline'"
                 @click="$emit('close-modal')"
    >
      Close
    </base-button>
  </div>
</template>

<script>
import {httpRequest} from "@/api";
import AutosuggestsList from "@/components/Modals/AutosuggestsList/index.vue";
import TranslateTool from "@/components/DataTables/Tools/TranslateTool/index.vue";
import translates from "@/api/modules/translates";

export default {
  name: "AutosuggestsModal",
  components: {
    TranslateTool,
    AutosuggestsList,
  },
  props: {
    currentCountryName: {
      type: String,
    },
    currentCountryCode: {
      type: String,
    },
    currentKeywordItem: {},
    currentApp: {
      type: Object,
    }
  },
  emits: [
    'save-keywords',
    'keywords-changed',
    'keywords-added',
    'keyword-removed',
    'close-modal',
  ],
  data() {
    return {
      showSuggestionsDataLoader: false,
      suggestedKeywords: [],
      showRelatedDataLoader: false,
      relatedKeywords: [],
      showTranslation: false,
    }
  },
  mounted() {
    this.fetchAutoSuggestKeywords();
    this.fetchRelatedKeywords();
  },
  methods: {
    removeKeyword(id, term) {
      this.$emit('keywords-changed');
      this.$emit('keyword-removed', id, term);

      let suggestedIndex = this.suggestedKeywords.findIndex(suggest => suggest?.id === id);
      if (suggestedIndex > -1) {
        this.suggestedKeywords[suggestedIndex].selected = false;
      }
      let relatedIndex = this.relatedKeywords.findIndex(suggest => suggest?.id === id);
      if (relatedIndex > -1) {
        this.relatedKeywords[relatedIndex].selected = false;
      }
    },
    newKeywordAdded(list) {
      this.$emit('keywords-changed');
      this.$emit('keywords-added', list);

      Object.keys(list).forEach(keyword => {
        let suggestedIndex = this.suggestedKeywords.findIndex(suggest => suggest?.keyword === keyword);
        if (suggestedIndex > -1) {
          this.suggestedKeywords[suggestedIndex].id = list[keyword];
          this.suggestedKeywords[suggestedIndex].selected = true;
        }
        let relatedIndex = this.relatedKeywords.findIndex(suggest => suggest?.keyword === keyword);
        if (relatedIndex > -1) {
          this.relatedKeywords[relatedIndex].id = list[keyword];
          this.relatedKeywords[relatedIndex].selected = true;
        }
      });
    },
    async fetchAutoSuggestKeywords() {
      this.showSuggestionsDataLoader = true;

      let urlQuery = '?country_code=' + this.currentCountryCode + '&keyword=' + encodeURIComponent(this.currentKeywordItem)
        + '&application_id=' + this.currentApp.id;
      let result = await httpRequest('GET', process.env.VUE_APP_API_URL
        + this.$ApiUrls.keywordsTracking.SUGGESTED_KEYWORDS + urlQuery);

      this.suggestedKeywords = result?.suggestion?.map(suggestion => {
        return {
          keyword: suggestion?.keyword,
          is_included: suggestion?.is_included,
          sap: suggestion?.SAP,
          selected: false,
          frozen: suggestion?.is_included ?? false,
        };
      });

      this.showSuggestionsDataLoader = false;
    },
    async fetchRelatedKeywords() {
      this.showRelatedDataLoader = true;

      let urlQuery = '?country_code=' + this.currentCountryCode + '&keyword=' + encodeURIComponent(this.currentKeywordItem)
        + '&app_id=' + this.currentApp.id + '&size=10&sort_by=sap&order=desc';
      let result = await httpRequest('GET', process.env.VUE_APP_API_URL
        + this.$ApiUrls.keywordsTracking.RELATED_KEYWORDS + urlQuery);

      this.relatedKeywords = result?.related?.map(related => {
        return {
          keyword: related?.keyword?.term,
          is_included: related?.keyword?.is_included,
          sap: related?.SAP,
          selected: false,
          frozen: related?.keyword?.is_included ?? false,
        };
      }) ?? [];

      this.showRelatedDataLoader = false;
    },
    clearTranslations() {
      this.suggestedKeywords.forEach((item) => {
        item.translation = null;
      });
      this.relatedKeywords.forEach((item) => {
        item.translation = null;
      });
    },
    async translateClickHandler() {
      this.showTranslation = !this.showTranslation;
      if (!this.showTranslation) {
        this.clearTranslations();
      } else {
        let keywords = this.suggestedKeywords.concat(this.relatedKeywords);
        let translatesByKeyword = await translates.translateKeywords(this, this.currentApp?.store?.key, keywords.map(item => item.keyword));
        this.suggestedKeywords.forEach((item) => {
          item.translation = translatesByKeyword[item.keyword] ?? null;
        });
        this.relatedKeywords.forEach((item) => {
          item.translation = translatesByKeyword[item.keyword] ?? null;
        });
      }
    },
  },
}
</script>

<style lang="scss" src="./styles.scss"></style>
