import {httpRequest} from "../index";

export default class KeywordsGroups {
    create = (app_id, name, desc = '', icon = 'icon') => {
        return httpRequest('POST', process.env.VUE_APP_API_URL + 'api/keywords-groups/create', {
            app_id: app_id,
            name: name,
            description: desc,
            icon: icon
        });
    }

    update = (id, name, desc = '', icon = '') => {
        return httpRequest('POST',process.env.VUE_APP_API_URL + 'api/keywords-groups/update', {
            id: id,
            name: name,
            description: desc,
            icon: icon
        });
    }

    delete = (group_id) => {
        return httpRequest('POST',process.env.VUE_APP_API_URL + 'api/keywords-groups/delete', {
            group_id: group_id
        });
    }
}