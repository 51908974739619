<script>
import SvgIcon from "../../UI/SvgIcon/index.vue";
import {arraySum} from "../../../utils/utils";

export default {
  name: "Tabs",
  components: {SvgIcon},

  props: {
    items: {
      required: true
    },
    selectedGroup: {
      required: true
    },
    groupsInfo: {
      required: true
    },
  },

  emits: [
    'selectTab',
  ],

  data() {
    return {
      tabSelectOpen: false,
      selectedTab: null,
    }
  },

  computed: {
    selectedHiddenTab() {
      return this.selectedTab === 'Other';
    },
    tabs() {
      let tabs = {
        showed: [],
        hidden: [],
      };

      if (this.selectedGroup && this.groupsInfo[this.selectedGroup.id]) {
        let tabsArray = [];
        for (let index of Object.keys(this.groupsInfo[this.selectedGroup.id])) {
          let tab = {
            index,
            name: this.selectedGroup.indexToLabel(index),
            count: this.groupsInfo[this.selectedGroup.id][index],
          };
          tabsArray.push(tab);
        }

        if (this.selectedGroup.sortBy) {
          tabsArray.sort((a, b) => {
            if (this.selectedGroup.sortBy === 'name') {
              return a.name.localeCompare(b.name);
            } else {
              return b.count - a.count;
            }
          });
        }

        for (let tab of tabsArray) {
          if (tab.name.includes('||Other')) {
            tab.name = tab.name.replace('||Other', '');
            tabs.hidden.push(tab);
          } else {
            tabs.showed.push(tab);
          }
        }
      }

      return tabs;
    },
  },

  methods: {
    arraySum,
    selectTab(tab) {
      this.selectedTab = tab;
      this.$emit('selectTab', tab);
    },
  }
}
</script>

<template>
  <div class="display-flex mb-10 common-scrollbar scroll-color-primary" style="overflow-x: scroll">
    <div class="table_tab" :class="{active: !selectedTab}" @click="selectTab(null)">
      <div class="tab_label">All Keywords</div>
      ({{ items.length }})
    </div>
    <template v-if="selectedGroup">
      <div class="table_tab" :class="{active: selectedTab === tab.index}" v-for="tab in tabs.showed"
           @click="selectTab(tab.index)">
        <div class="tab_label">{{ tab.name }}</div>
        ({{ tab.count }})
      </div>
      <template v-if="tabs.hidden.length">
        <div style="position: relative">
          <div class="table_tab tab_select_trigger"
               :class="{active: selectedHiddenTab}"
               @click="selectTab('Other')"
          >
            <div class="tab_label">Other Pop. 6+</div>
            ({{
              arraySum(tabs.hidden.map((tab) => {
                return tab.count
              }))
            }})
          </div>
        </div>
      </template>
    </template>
<!--    TODO-->
<!--    <template v-if="selectedGroup?.id === 'by_folders'">-->
<!--      <div class="table_tab active" @click="startAddGroup">-->
<!--        <div class="tab_label">-->
<!--          <svg-icon icon="add-folder-fill"/>-->
<!--          <span class="ml-8">Add</span></div>-->
<!--      </div>-->
<!--    </template>-->
  </div>
</template>

<style scoped lang="scss">
.table_tab {
  margin-right: 4px;
  cursor: pointer;
  user-select: none;
  font-weight: 400;
  color: var(--neutral-750);
  font-size: 14px;
  display: flex;
  align-items: center;
  line-height: 16px;
  padding: 6px 12px;
  white-space: nowrap;
  border-radius: 4px;

  .tab_label {
    font-weight: 600;
    font-size: 13px;
    margin-right: 6px;
    text-transform: capitalize;
  }

  &.active {
    .tab_label {
      color: var(--primary-default);
    }
  }
}

.tab_select_trigger {
  background-color: var(--neutral-300);
  margin-right: 0;
}

.tab_select {
  position: absolute;
  z-index: 3;
  background-color: #fff;
  max-height: 250px;
  overflow-y: scroll;
  right: 0;
  bottom: 0;
  transform: translate(0, calc(100% + 4px));
  box-shadow: 0px 2px 6px 0px rgba(160, 190, 221, 0.40);
  border-radius: 4px;

  .tab_select_item {
    padding: 6px 16px;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
      background-color: var(--neutral-400);
    }
  }
}
</style>